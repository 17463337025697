import { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import settingsQuery from "../graphql/settings.query.graphql";
import {
  ContextEDH,
  defaultBookingClassroomOrder,
  defaultGroupInLinkRestriction,
} from "@context/ContextEDH";
import { BookingClassroomOrderPayload, Types } from "@context/base.reducer";
import { BaseQuery, SettingFilterInput } from "@models/ISchema";
import { Setting } from "@models/ISchema";

enum settingsType {
  institutionCode = "Institution.code",
  mouseflowProjectId = "Mouseflow.projectId",
  groupsManagerHideColumnsFilter = "GroupsManager.hide_columns_filter",
  SchedulerEditorOrderClassroomBookings = "SchedulerEditor.order_classroom_bookings",
  SchedulerEditorRestrictGroupsInLink = "SchedulerEditor.restrict_groups_in_link",
  BookingsProgramRequired = "Bookings.program_required",
}

type SettingsObj = { [key: string]: Setting };

const getSettingsObj = (settings: Setting[]) => {
  const settingsObj: SettingsObj = {};

  settings.forEach(setting => {
    settingsObj[setting.config] = setting;
  });

  return settingsObj;
};

const getGroupsInLinkRestriction = (settingsObj: SettingsObj) => {
  let groupsInLinkRestrictionValue = defaultGroupInLinkRestriction;
  const groupsInLinkRestriction = settingsObj[settingsType?.SchedulerEditorRestrictGroupsInLink];

  if (groupsInLinkRestriction?.value) {
    groupsInLinkRestrictionValue = JSON.parse(groupsInLinkRestriction?.value) as {
      enabled: boolean;
      groups_allowed: number;
    };
  }

  return groupsInLinkRestrictionValue;
};

const useGetSettings = (): [{ data: boolean; error: boolean; loading: boolean }, () => void] => {
  const client = useApolloClient();
  const { dispatch } = useContext(ContextEDH);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const mouseflowConnect = (settingsObj: SettingsObj) => {
    const findInstitution = settingsObj[settingsType?.institutionCode];
    const findProjectId = settingsObj[settingsType?.mouseflowProjectId];

    if (findProjectId) {
      const projectId = findProjectId?.value.replaceAll('"', "");
      const mf = document.createElement("script");
      mf.type = "text/javascript";
      mf.defer = true;
      mf.src = `/mf/projects/${projectId}.js`;
      document.getElementsByTagName("head")[0].appendChild(mf);
      dispatch({ type: Types.SetMouseflowEnabled, payload: true });
    }

    if (findInstitution && findProjectId) {
      window["_mfq"] = window["_mfq"] || [];
      window["_mfq"].push(["tag", findInstitution?.value.replaceAll('"', "")]);
    }
  };

  const setBookingsProgramRequired = (settingsObj: SettingsObj) => {
    const bookingsProgramRequired = settingsObj[settingsType?.BookingsProgramRequired];
    const value = !!bookingsProgramRequired?.value
      ? JSON.parse(bookingsProgramRequired?.value)
      : null;

    dispatch({
      type: Types.setBookingsProgramRequired,
      payload: value || "HIDDEN",
    });
  };

  const setBookingClassroomOrder = (settingsObj: SettingsObj) => {
    const bookingClassroomOrder = settingsObj[settingsType?.SchedulerEditorOrderClassroomBookings];
    const value = !!bookingClassroomOrder?.value
      ? (JSON.parse(bookingClassroomOrder?.value) as {
          enabled: boolean;
          classroom_availabilities_order: BookingClassroomOrderPayload;
        })
      : null;

    dispatch({
      type: Types.setBookingClassroomOrder,
      payload: value?.enabled
        ? (value?.classroom_availabilities_order as BookingClassroomOrderPayload)
        : defaultBookingClassroomOrder,
    });
  };

  const setGroupsInLinkRestriction = (settingsObj: SettingsObj) => {
    const groupsInLinkRestrictionValue = getGroupsInLinkRestriction(settingsObj);

    dispatch({
      type: Types.setGroupsInLinkRestriction,
      payload: groupsInLinkRestrictionValue,
    });
  };

  const setGeneralSettings = (settingsObj: SettingsObj) => {
    const groupsManagerHideColumnsFilter =
      settingsObj[settingsType?.groupsManagerHideColumnsFilter];
    const shouldHideGroupsColumnsFilter = ["1", "true"].includes(
      groupsManagerHideColumnsFilter?.value || "",
    );

    setBookingClassroomOrder(settingsObj);
    setGroupsInLinkRestriction(settingsObj);
    setBookingsProgramRequired(settingsObj);

    dispatch({
      type: Types.setGroupManagerFilterEnabled,
      payload: !shouldHideGroupsColumnsFilter,
    });
  };

  const getSettings = async () => {
    setLoading(true);
    try {
      if (!localStorage.getItem("token")) return;

      const filter: SettingFilterInput = {
        fields: {
          config: {
            in: [
              settingsType.institutionCode,
              settingsType.mouseflowProjectId,
              settingsType.groupsManagerHideColumnsFilter,
              settingsType.SchedulerEditorOrderClassroomBookings,
              settingsType.SchedulerEditorRestrictGroupsInLink,
              settingsType.BookingsProgramRequired,
            ],
          },
        },
      };
      const variables = {
        query: settingsQuery,
        variables: {
          filter,
        },
      };
      const { data } = await client.query(variables);
      const baseQuery: BaseQuery = data?.base;
      const settings = baseQuery?.settings?.items;
      dispatch({ type: Types.SetSettings, payload: settings });

      if (settings?.length > 0) {
        const settingsObj = getSettingsObj(settings);

        mouseflowConnect(settingsObj);
        setGeneralSettings(settingsObj);
      }

      setResult(true);
      setError(false);
      setLoading(false);
    } catch (error) {
      console.error("[Settings]", error);
      dispatch({ type: Types.SetSettings, payload: [] });
      setResult(true);
      setError(true);
      setLoading(false);
    }
  };

  return [{ data: result, error, loading }, getSettings];
};

export default useGetSettings;
